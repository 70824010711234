#leads-site-pib-68e07195e6296c6886cb {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 999;

  &.remove {
    opacity: 0;
    pointer-events: none;
  }

  .modal-close {
    color: black;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    z-index: 5;
  }
}