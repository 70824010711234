#page-home {
	position: relative;
	overflow: hidden;

	/** Page background. */
	.page-background {
		position: absolute;
		top: 1150px;
		left: 0;
		width: 100%;
		overflow: hidden;
		pointer-events: none;

		/** Desktop. */
		@media (min-width: 1600px) {
			top: 700px;
		}

		/** Mobile. */
		@media (max-width: 1023px) {
			display: none;
		}

		img {
			width: 100%;
		}
	}

	/** Mobile cta. */
	.mobile-cta {
		background-color: color(blue, light);
		z-index: 55;
		position: fixed;
		right: 0;
		bottom: 0;
		left: 0;
		height: 60px;
		transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

		button {
			color: white;
			font-size: 16px;
			font-weight: bold;
			text-transform: uppercase;
			position: relative;
			width: 100%;
			height: 100%;
		}

		&.modal-open {
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
		}
	}

	/** General section. */
	.section {
		position: relative;
	}

	/** Section hero. */
	.section-hero {
		height: 1040px;
		z-index: 2;

		/** Mobile. */
		@media (max-width: 1023px) {
			height: 700px;
		}

		.background {
			background-color: color(blue, base);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;

			&::after {
				content: '';
				background-image: linear-gradient(to top, color(blue, base) 12%, fade-out(color(blue, base), 1));
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 2;
			}

			img {
				position: relative;
				width: 100%;
				height: 100%;
				object-fit: cover;
				opacity: .9;
				z-index: 1;
			}
		}

		.wrapper {
			display: flex;
			align-items: center;
			text-align: center;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 2;

			&-title {
				color: white;
				font-size: 40px;
				font-weight: 900;
				line-height: 1.5;
				width: 780px;
				margin: 0 auto;
				position: relative;
				text-transform: uppercase;

				/** Mobile. */
				@media (max-width: 1023px) {
					width: 100%;
					font-size: 24px;
				}
				@media (max-width: 355px) {
					font-size: 21px;
					line-height: 1.4;
				}

				&::before {
					content: '';
					background-color: color(green);
					position: absolute;
					top: -30px;
					left: 50%;
					width: 75px;
					height: 4px;
					transform-origin: 50% 50%;
					transform: scaleX(1) translateX(-50%);
					transition: transform time(default) ease(inout);
					z-index: 2;
				}
			}

			&-description {
				width: 470px;
				margin: 25px auto 110px;

				/** Mobile. */
				@media (max-width: 1023px) {
					width: 100%;
					margin: 25px 0 40px;
				}

				p {
					color: white;
					font-size: 18px;
					font-weight: 500;
					line-height: 1.7;

					@media (max-width: 355px) {
						font-size: 14px;
					}
				}
			}

			&--scroll-down {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.text {
					color: white;
					font-size: 12px;
					font-weight: bold;
					text-transform: uppercase;
					letter-spacing: 1.5px;
					opacity: .5;
					margin-bottom: 15px;
				}

				/** Animation scroll down. */
				@keyframes scroll_down {
					0%, 100% { transform: translateY(0); }
					50% { transform: translateY(10px); }
				}

				.icon {
					animation-name: scroll_down;
					animation-duration: 2s;
					animation-timing-function: ease(inout);
					animation-iteration-count: infinite;

					svg path {
						fill: color(green);
					}
				}
			}
		}
	}

	/** Section video. */
	.section-video {
		z-index: 3;
		margin: -165px 0 0;

		/** Mobile. */
		@media (max-width: 1023px) {
			margin: -100px 0 0;
		}

		.video {
			background-color: color(blue, base);
			position: relative;
			width: 770px;
			height: 435px;
			margin: 0 auto;
			box-shadow: 0px 40px 80px 0px rgba(0, 17, 30, 0.49);

			/** Appear. */
			@media (min-width: 1024px) {
				opacity: 0;
				visibility: hidden;
				transform: translateY(10px);
				transition: opacity time(default) ease(inout), visibility time(default) ease(inout), transform time(default) ease(inout);
				
				&.appear {
					opacity: 1;
					visibility: visible;
					transform: translateY(0px);
				}
			}

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
				height: 200px;
			}

			&-link {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 3;
			}

			&-background {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					opacity: .5;
					transition: transform time(default) ease(inout);
				}
			}

			&-icon {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;

				&::after {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 170px;
					height: 170px;
					border: 1px solid white;
					border-radius: 50%;
					opacity: .5;
					transition: transform time(default) ease(inout), opacity time(default) ease(inout);

					/** Mobile. */
					@media (max-width: 1023px) {
						width: 120px;
						height: 120px;
					}
				}

				svg {
					transition: transform time(default) ease(inout);
					transform: scale(1);

					path {
						fill: white;
					}
				}
			}

			/** Desktop. */
			@media (min-width: 1024px) {
				&:hover {
					.video-background img {
						transform: scale(1.05);
					}

					.video-icon {
						&::after {
							transform: translate(-50%, -50%) scale(0.95);
							opacity: 0;
						}

						svg {
							transform: scale(1.5);
						}
					}
				}
			}
		}
	}

	/** Section about. */
	.section-about {
		z-index: 2;
		padding: 120px 0;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 80px 0;
		}

		.description {
			width: 585px;
			margin-bottom: 90px;

			/** Appear. */
			@media (min-width: 1024px) {
				opacity: 0;
				visibility: hidden;
				transform: translateY(10px);
				transition: opacity time(default) ease(inout), visibility time(default) ease(inout), transform time(default) ease(inout);
				
				&.appear {
					opacity: 1;
					visibility: visible;
					transform: translateY(0px);
				}
			}

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
				margin-bottom: 70px;
			}

			p {
				color: color(blue, base);
				font-size: 16px;
				font-weight: 500;
				line-height: 1.7;
			}
		}

		.tour {
			position: relative;
			width: 100%;
			height: 560px;
			margin-bottom: 90px;

			/** Mobile. */
			@media (max-width: 1023px) {
				height: 450px;
			}

			iframe {
				width: 100%;
				height: 100%;
				display: block;
			}
		}

		.accordions {
			position: relative;
			width: 100%;

			.accordion {
				position: relative;
				width: 100%;

				&:not(:last-child) {
					margin-bottom: 25px;
				}

				&-header {
					background-color: color(blue, light);
					display: flex;
					align-items: center;
					justify-content: space-between;
					text-align: left;
					width: 100%;
					min-height: 50px;
					padding: 10px 15px 10px 10px;
					transition: background-color time(default) ease(inout);

					span {
						color: white;
						font-size: 16px;
						font-weight: bold;
						line-height: 1.7;
					}

					svg {
						flex-shrink: 0;
						margin-left: 10px;

						path {
							fill: color(green);
						}
					}
				}

				&-wrapper {
					position: relative;
					width: 100%;
					overflow: hidden;
					border: 1px solid color(blue, light);
					border-top: unset;
					transition: height time(default) ease(inout), border-color time(default) ease(inout);

					&--content {
						padding: 25px;

						p {
							color: color(blue, base);
							font-size: 16px;
							font-weight: 500;
							line-height: 1.7;
						}
					}
				}

				/** Active. */
				&.active {
					.accordion-header {
						background-color: color(blue, base);
					}

					.accordion-wrapper {
						border-color: color(blue, base);
					}
				}
			}
		}

		.flex-wrapper {
			display: flex;
			padding-right: 100px;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
			}

			.navigation {
				position: relative;
				width: 520px;
				flex-shrink: 0;
				margin-right: 30px;
				z-index: 2;
				transition: opacity time(slow) ease(inout);

				&.disabled {
					opacity: .4;
					pointer-events: none;
				}

				ul li {
					background-color: color(blue, light);
					position: relative;
					width: 100%;
					height: 60px;
					transition: background-color time(fast) ease(fade);

					/** Appear. */
					@media (min-width: 1024px) {
						opacity: 0;
						transform: translateX(-10px);
						transition: opacity time(default) ease(inout), transform time(default) ease(inout);
						
						&.appear {
							opacity: 1;
							transform: translateY(0px);
						}
					}

					&:not(:last-child) {
						margin-bottom: 13px;
					}

					&::after {
						content: '';
						position: absolute;
						top: 50%;
						right: -30px;
						transform: translate(10px, -50%);
						width: 15px;
						height: 1px;
						border-top: 15px solid transparent;
						border-right: 15px solid white;
						border-bottom: 15px solid transparent;
						opacity: 0;
						transition: transform time(default) ease(inout), opacity time(default) ease(inout);
					}

					button {
						position: relative;
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 0 30px 0 40px;

						span {
							color: white;
							font-size: 16px;
							font-weight: bold;
							line-height: 1.7;
						}

						svg {
							flex-shrink: 0;
							margin-left: 15px;
							transform: translateX(-10px);
							opacity: 0;
							transition: transform time(default) ease(inout), opacity time(default) ease(inout);

							path {
								fill: color(green);
							}
						}
					}

					&.active {
						&::after {
							opacity: 1;
							transform: translate(0, -50%);
							transition-delay: .6s;
						}
					}

					&:hover,
					&.active {
						background-color: color(blue, base);

						button svg {
							opacity: 1;
							transform: translateX(0);
						}
					}
				}
			}

			.tabs {
				position: relative;
				flex-grow: 1;
				z-index: 1;

				/** Appear. */
				@media (min-width: 1024px) {
					opacity: 0;
					transition: opacity time(default) ease(inout);
					
					&.appear {
						opacity: 1;
					}
				}

				.tab-content {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 1;
					user-select: none;

					&::before {
						content: '';
						background-color: white;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 1;
						transform: scaleX(0);
						transform-origin: 0% 50%;
						box-shadow: 0px 40px 80px 0px rgba(0, 17, 30, 0);
						transition: transform time(fast) ease(inout), box-shadow time(default) ease(inout);
						transition-delay: .3s, 0s;
					}

					&--description {
						position: relative;
						z-index: 2;
						width: 100%;
						height: 100%;
						padding: 30px 50px;
						overflow: auto;
						opacity: 0;
						transition: opacity time(default) ease(inout);
						transition-delay: 0s;

						&::-webkit-scrollbar {
							width: 3px;
						}
						&::-webkit-scrollbar-track {
							background: color(blue, base);
						}
						&::-webkit-scrollbar-thumb {
							background: color(green);
						}

						p {
							color: color(blue, base);
							font-size: 16px;
							font-weight: 500;
							line-height: 1.7;
						}
					}

					&.active {
						user-select: unset;
						z-index: 2;

						&::before {
							transform: scaleX(1);
							box-shadow: 0px 40px 80px 0px rgba(0, 17, 30, 0.19);
							transition-delay: .6s;
						}

						.tab-content--description {
							opacity: 1;
							transition-delay: .9s;
						}
					}
				}
			}

			/** Appear. */
			&.appear {
				.navigation ul li {
					opacity: 1;
					transform: translateX(0);

					/** @each: Delay. */
					@for $i from 1 to 10 {
						&:nth-child(#{$i}) {
							transition-delay: #{.05s * $i + .7s};
						}
					}
				}

				.tabs {
					opacity: 1;
					transition-delay: 600ms;
				}
			}
		}
	}

	/** Section features. */
	.section-features {
		padding: 120px 0 75px;
		z-index: 2;
		overflow: hidden;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 40px 0 80px;
		}

		.title {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;

			/** Mobile. */
			@media (max-width: 1023px) {
				color: color(green);
				font-size: 45px;
				font-weight: 900;
				position: relative;
				width: 100%;
				display: block;
				text-align: center;
				top: unset;
				left: unset;
				opacity: 1;
				visibility: visible;
				pointer-events: unset;
				margin-bottom: 60px;

				span {
					color: color(blue, base);
					display: block;
				}

				strong {
					color: color(blue, light);
					font-weight: 900;
					display: block;
				}
			}
			@media (max-width: 355px) {
				font-size: 35px;
			}
		}

		.title-animation {
			color: color(blue, base);
			font-size: 95px;
			font-weight: 900;
			width: 100%;
			text-align: center;
			position: relative;
			margin-bottom: 50px;
			z-index: 2;

			span {
				color: color(blue, light);
			}

			strong {
				color: color(green);
				font-weight: 900;
				display: block;
			}

			/** Appear. */
			@media (min-width: 1024px) {
				opacity: 0;
				transition: opacity time(default) ease(inout);
				
				&.appear {
					opacity: 1;
				}
			}

			/** Mobile. */
			@media (max-width: 1023px) {
				display: none;
			}
		}

		.flex-wrapper {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-column-gap: 30px;
			padding: 50px 0 0;

			/** Mobile. */
			@media (max-width: 1023px) {
				padding: 0;
				grid-row-gap: 30px;
				grid-template-columns: repeat(2, 1fr);
			}
			@media (max-width: 767px) {
				grid-template-columns: repeat(1, 1fr);
			}

			.item {
				background-color: color(blue, base);
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 310px;
				border: 1px solid color(blue, base);

				/** Appear. */
				@media (min-width: 1024px) {
					opacity: 0;
					transform: translateY(10px);
					transition: opacity time(default) ease(inout), transform time(default) ease(inout);
				}

				/** Mobile. */
				@media (max-width: 1023px) {
					width: 100%;
					height: auto;
					padding: 40px 0;
				}

				&-icon {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100px;

					svg path {
						fill: color(blue, light);
					}
				}

				&-title {
					color: color(green);
					font-size: 18px;
					font-weight: 900;
					text-transform: uppercase;
					margin: 20px 0 15px;
				}

				&-subtitle {
					color: white;
					font-size: 22px;
					font-weight: bold;
					text-align: center;

					/** Mobile. */
					@media (max-width: 1023px) {
						font-size: 18px;
					}

					p {
						line-height: 1.4;
					}

					strong {
						font-weight: 900;
					}
				}
			}

			/** Appear. */
			@media (min-width: 1024px) {
				&.appear .item {
					opacity: 1;
					transform: translateY(0px);

					/** @each: Delay. */
					@for $i from 1 to 10 {
						&:nth-child(#{$i}) {
							transition-delay: #{.05s * $i + .7s};
						}
					}
				}
			}
		}
	}

	/** Section differentials. */
	.section-differentials {
		padding: 100px 0 50px;
		z-index: 3;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0;
		}

		.title {
			color: color(blue, base);
			font-size: 44px;
			font-weight: 900;
			margin-bottom: 65px;
			padding: 0 100px;

			/** Appear. */
			@media (min-width: 1024px) {
				opacity: 0;
				transform: translateX(-10px);
				transition: opacity time(default) ease(inout), transform time(default) ease(inout);
				
				&.appear {
					opacity: 1;
					transform: translateY(0px);
				}
			}

			/** Mobile. */
			@media (max-width: 1023px) {
				font-size: 34px;
				padding: 0;
				margin-bottom: 50px;
				line-height: 1.7;
				text-align: center;
			}
		}

		.accordions {
			position: relative;
			width: 100%;

			.accordion {
				position: relative;
				width: 100%;

				&:not(:last-child) {
					margin-bottom: 25px;
				}

				&-header {
					background-color: color(blue, light);
					display: flex;
					align-items: center;
					justify-content: space-between;
					text-align: left;
					width: 100%;
					min-height: 50px;
					padding: 10px 15px 10px 10px;
					transition: background-color time(default) ease(inout);

					span {
						color: white;
						font-size: 16px;
						font-weight: bold;
						line-height: 1.7;
					}

					svg {
						flex-shrink: 0;
						margin-left: 10px;

						path {
							fill: color(green);
						}
					}
				}

				&-wrapper {
					position: relative;
					width: 100%;
					overflow: hidden;
					transition: height time(default) ease(inout);

					&--content {
						img {
							width: 100%;
							object-fit: cover;
						}

						.legend {
							color: black;
							text-align: center;
							font-size: 14px;
							font-weight: bold;
							line-height: 1.7;
							padding: 10px 15px;
							z-index: 2;
						}
					}
				}

				/** Active. */
				&.active {
					.accordion-header {
						background-color: color(blue, base);
					}

					.accordion-wrapper {
						border-color: color(blue, base);
					}
				}
			}
		}

		.flex-wrapper {
			display: flex;
			align-items: flex-start;

			.navigation {
				position: relative;
				width: 300px;
				flex-shrink: 0;
				margin-right: 30px;
				z-index: 2;
				transition: opacity time(slow) ease(inout);

				&.disabled {
					opacity: .4;
					pointer-events: none;
				}

				ul li {
					position: relative;
					width: 100%;
					height: 40px;
					transition: background-color time(fast) ease(fade);
					transition-delay: .3s;

					/** Appear. */
					@media (min-width: 1024px) {
						opacity: 0;
						transform: translateX(-10px);
						transition: opacity time(default) ease(inout), transform time(default) ease(inout);
					}

					&:not(:last-child) {
						margin-bottom: 5px;
					}

					&::after {
						content: '';
						position: absolute;
						top: 50%;
						right: -22px;
						transform: translate(-10px, -50%);
						width: 11px;
						height: 1px;
						border-top: 11px solid transparent;
						border-left: 11px solid color(green);
						border-bottom: 11px solid transparent;
						opacity: 0;
						transition: transform time(default) ease(inout), opacity time(default) ease(inout);
					}

					button {
						position: relative;
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 0 20px;

						span {
							color: color(blue, base);
							font-size: 16px;
							font-weight: bold;
							line-height: 1.7;
						}

						svg {
							flex-shrink: 0;
							margin-left: 15px;
							transform: translateX(-10px);
							opacity: 0;
							transition: transform time(default) ease(inout), opacity time(default) ease(inout);

							path {
								fill: color(blue, base);
							}
						}
					}

					&.active {
						&::after {
							opacity: 1;
							transform: translate(0, -50%);
							transition-delay: .6s;
						}
					}

					&:hover,
					&.active {
						background-color: color(green);
						transition-delay: 0s;

						button svg {
							opacity: 1;
							transform: translateX(0);
						}
					}
				}
			}

			.tabs {
				position: relative;
				height: 510px;
				flex-grow: 1;
				z-index: 1;
				// box-shadow: 0px 40px 80px 0px rgba(8, 41, 67, 0.6);
				overflow: hidden;

				&::before,
				&::after {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					transform: translateX(-100%);
				}

				&::before {
					background-color: color(green);
					z-index: 3;
				}

				&::after {
					background-color: color(blue, base);
					z-index: 4;	
				}

				.tab-content {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 1;
					opacity: 0;
					transition: opacity time(slow) ease(inout);
					transition-delay: .5s;

					&--image {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 1;
						transform-origin: 25% 25%;
						transform: scale(1.05);
						transition: transform 1.5s ease(inout);
						transition-delay: .5s;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					&--legend {
						background-color: fade-out(black, .5);
						color: white;
						font-size: 14px;
						font-weight: bold;
						line-height: 1.7;
						padding: 10px 15px;
						opacity: 0;
						transition: opacity 1.5s ease(inout);
						position: absolute;
						right: 15px;
						bottom: 15px;
						z-index: 2;
					}

					&.active {
						opacity: 1;
						transition: opacity .2s ease(inout);
						transition-delay: .7s;
						z-index: 2;

						.tab-content--image {
							transform: scale(1);
							transition-delay: 1s;
						}

						.tab-content--legend {
							opacity: 1;
							transition-delay: 1.5s;
						}
					}
				}

				/** Animation. */
				&.animate {
					&::before {
						transform: translateX(0%);
						transition: transform time(default) ease(inout);
					}
				}

				&.remove {
					&::before,
					&::after {
						transition: transform 1.5s ease(inout);
						transform: translateX(100%);
					}

					&::before {
						transition-delay: .3s;
					}

					&::after {
						transition-delay: 0s;
					}
				}
			}

			/** Appear. */
			&.appear {
				.navigation ul li {
					opacity: 1;
					transform: translateX(0);

					/** @each: Delay. */
					@for $i from 1 to 10 {
						&:nth-child(#{$i}) {
							transition-delay: #{.05s * $i + .7s};
						}
					}
				}
			}
		}
	}

	/** Section betim. */
	.section-betim {
		z-index: 2;
		margin: -135px 0 0;
		padding: 280px 0 480px;

		/** Mobile. */
		@media (max-width: 1023px) {
			margin: 0;
			padding: 80px 0;
		}

		.background {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.container {
			position: relative;
			z-index: 2;
		}

		.flex-wrapper {
			display: flex;
			align-items: flex-start;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
			}

			.left {
				width: 495px;
				flex-shrink: 0;
				margin-right: 120px;

				/** Appear. */
				@media (min-width: 1024px) {
					opacity: 0;
					transition: opacity time(default) ease(inout);
					
					&.appear {
						opacity: 1;
					}
				}

				/** Mobile. */
				@media (max-width: 1023px) {
					width: 100%;
					margin: 0 0 60px;
				}

				&-title {
					color: color(green);
					font-size: 60px;
					font-weight: 900;
					text-transform: uppercase;

					/** Mobile. */
					@media (max-width: 1023px) {
						font-size: 28px;
					}
				}

				&-subtitle {
					color: white;
					font-size: 44px;
					font-weight: bold;
					margin: 10px 0 35px;

					/** Mobile. */
					@media (max-width: 1023px) {
						font-size: 21px;
					}
				}

				&-description p {
					color: white;
					font-size: 16px;
					font-weight: 500;
					line-height: 1.7;

					&:not(:last-child) {
						margin-bottom: 20px;
					}
				}
			}

			.right {
				flex-grow: 1;

				/** Appear. */
				@media (min-width: 1024px) {
					opacity: 0;
					transition: opacity time(default) ease(inout);
					
					&.appear {
						opacity: 1;
					}
				}

				/** Mobile. */
				@media (max-width: 1023px) {
					flex-grow: unset;
					width: 100%;
				}

				&-title {
					color: color(green);
					font-size: 24px;
					font-weight: 900;
					text-transform: uppercase;
				}

				&-list {
					margin-top: 35px;

					.item {
						display: flex;
						align-items: center;

						&:not(:last-child) {
							margin-bottom: 15px;
						}

						&-icon svg path {
							fill: color(green);
						}

						&-text {
							color: white;
							font-size: 16px;
							font-weight: bold;
							margin-left: 15px;
							line-height: 1.4;

							/** Mobile. */
							@media (max-width: 1023px) {
								margin-left: 10px;
							}
						}
					}
				}
			}
		}
	}

	/** Section location. */
	.section-location {
		margin: -420px 0 0;
		padding: 50px 0 10px;
		z-index: 3;

		/** Mobile. */
		@media (max-width: 1023px) {
			margin: 0;
			padding: 80px 0;
		}

		.title {
			color: white;
			font-size: 36px;
			font-weight: 900;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 20px;

			&:nth-child(1) {
				color: white;

				/** Mobile. */
				@media (max-width: 1023px) {
					color: color(blue, base);
				}
			}

			&:nth-child(4) {
				color: color(blue, base);
			}

			/** Appear. */
			@media (min-width: 1024px) {
				opacity: 0;
				transform: translateY(10px);
				transition: opacity time(default) ease(inout), transform time(default) ease(inout);
				
				&.appear {
					opacity: 1;
					transform: translateY(0px);
				}
			}

			/** Mobile. */
			@media (max-width: 1023px) {
				color: color(blue, base);
				font-size: 34px;
				margin-bottom: 60px;
			}
		}

		.location {
			color: white;
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 50px;

			/** Mobile. */
			@media (max-width: 1023px) {
				color: color(blue, base);
				line-height: 1.5;
			}
		}

		.box {
			position: relative;
			height: 540px;
			// box-shadow: 0px 40px 80px 0px rgba(8, 41, 67, 0.3);
			margin-bottom: 80px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100vw;
				height: 300px;
				left: -25px;
				box-shadow: unset;
			}

			&-link {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 2;
			}

			&-image {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}

		.flex-wrapper {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			margin-bottom: 60px;

			/** Appear. */
			@media (min-width: 1024px) {
				opacity: 0;
				transition: opacity time(default) ease(inout);
				
				&.appear {
					opacity: 1;
				}
			}

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
				justify-content: center;
			}

			.item {
				position: relative;
				width: 270px;

				/** Mobile. */
				@media (max-width: 1023px) {
					width: 100%;
				}

				&:not(:last-child) {
					margin-right: 100px;

					/** Mobile. */
					@media (max-width: 1023px) {
						margin: 0 0 50px;
					}
				}

				&-number {
					color: #E7E7E7;
					font-size: 112px;
					font-weight: bold;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
					z-index: 1;
					user-select: none;

					/** Mobile. */
					@media (max-width: 1023px) {
						left: -15px !important;
					}
				}

				&-description {
					position: relative;
					z-index: 2;

					p {
						color: color(blue, base);
						font-size: 18px;
						font-weight: 500;
						line-height: 1.7;

						/** Mobile. */
						@media (max-width: 1023px) {
							font-size: 16px;
						}
					}
				}

				/** Variations. */
				&:nth-child(1) .item-number {
					left: -60px;
				}
				&:nth-child(2) .item-number {
					left: -40px;
				}
				&:nth-child(3) .item-number {
					left: -30px;
				}
			}
		}

		.plant {
			position: relative;
			text-align: center;
			width: 100%;

			img {
				max-width: 100%;
				user-select: none;
			}
		}
	}

	/** Section cta. */
	.section-cta {
		background-color: white;
		margin: -50px 0 0;
		padding: 190px 0 0;
		overflow: hidden;
		z-index: 2;

		/** Mobile. */
		@media (max-width: 1023px) {
			display: none;
		}

		.background {
			background-color: color(blue, base);
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;

			&::before,
			&::after {
				content: '';
				position: absolute;
				right: 0;
				left: 0;
				z-index: 2;
			}

			&::before {
				background-image: linear-gradient(to bottom, white, fade-out(white, 1));
				top: 0;
				height: 300px;
			}

			&::after {
				background-image: linear-gradient(to top, color(blue, base) 10%, fade-out(color(blue, base), 1) 120%);
				bottom: 0;
				height: 500px;

				/** Desktop. */
				@media (min-width: 1600px) {
					background-image: linear-gradient(to top, color(blue, base) 10%, fade-out(color(blue, base), 1) 100%);
				}
			}

			img {
				width: 100%;
				opacity: .7;
			}
		}

		.container {
			position: relative;
			z-index: 2;
			border-bottom: 1px solid color(blue, light);
			padding: 0 0 100px;
		}

		.title {
			color: color(blue, base);
			font-size: 48px;
			font-weight: 900;
			line-height: 1.2;
			text-transform: uppercase;
			width: 100%;
			margin: 0 200px 150px;

			/** Appear. */
			@media (min-width: 1024px) {
				opacity: 0;
				transition: opacity time(default) ease(inout);
				
				&.appear {
					opacity: 1;
				}
			}
		}

		.form {
			width: 570px;
			margin: 0 auto 120px;

			/** Appear. */
			@media (min-width: 1024px) {
				opacity: 0;
				transition: opacity time(default) ease(inout);
				
				&.appear {
					opacity: 1;
				}
			}

			.field-group {
				position: relative;

				&:not(:last-child) {
					margin-bottom: 55px;
				}

				&::before,
				&::after {
					content: '';
					position: absolute;
					right: 0;
					bottom: 0;
					left: 0;
					height: 1px;
					transform-origin: 0% 50%;
					transition: transform time(default) ease(inout);
					transform: scaleX(0);
				}

				&::before {
					background-color: red;
					z-index: 3;
				}

				&::after {
					background-color: color(blue, light);
					z-index: 2;
				}
				
				.placeholder {
					position: absolute;
					top: -1px;
					left: 0;
					color: white;
					font-size: 24px;
					font-weight: bold;
					transform-origin: 0% 0%;
					transition: transform time(fast) ease(inout);
					pointer-events: none;
				}

				.field {
					background-color: transparent;
					color: white;
					font-size: 18px;
					font-weight: 500;
					padding: 0 0 15px;
					width: 100%;
					display: block;
					border: none;
					border-bottom: 1px solid color(green);
					outline: none;
				}

				textarea.field {
					resize: none;
					height: 100px;
				}

				&.focus,
				&.not-empty {
					.placeholder {
						transform: translateY(-20px) scale(.6);
					}

					&::after {
						transform: scaleX(1);
					}
				}

				&.invalid {
					&::before {
						transform: scaleX(1);
					}
				}
			}

			.form-submit {
				display: flex;
				justify-content: flex-end;
				
				button {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;

					&::before {
						content: '';
						background-color: color(green);
						position: absolute;
						top: 50%;
						right: -28px;
						transform: translateY(-50%);
						width: 80px;
						height: 80px;
						border-radius: 50%;
						transition: background-color time(fast) ease(inout);
					}

					span {
						color: white;
						font-size: 18px;
						font-weight: 900;
						text-transform: uppercase;
						margin-right: 60px;
					}

					svg {
						position: relative;
						z-index: 2;
						transition: transform time(fast) ease(inout);

						path {
							fill: white;
						}
					}

					&:hover {
						&::before {
							background-color: color(blue, light);
						}

						svg {
							transform: translate(3px, -3px);
						}
					}
				}
			}
		}

		.central {
			color: color(green);
			font-size: 24px;
			font-weight: 900;
			display: block;
			text-align: center;
			text-transform: uppercase;

			a {
				color: white;
				transition: color time(fast) ease(inout);

				&:hover {
					color: color(green);
				}
			}
		}
	}

	/** Section log. */
	.section-log {
		background-color: color(blue, base);
		padding: 90px 0 30px;
		z-index: 3;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 80px 0 30px;
		}

		.flex-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 70px;

			/** Appear. */
			@media (min-width: 1024px) {
				opacity: 0;
				transform: translateY(10px);
				transition: opacity time(default) ease(inout), transform time(default) ease(inout);
				
				&.appear {
					opacity: 1;
					transform: translateY(0px);
				}
			}

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
				justify-content: center;
				margin-bottom: 40px;
			}

			.image {
				position: relative;
				flex-shrink: 0;
				margin-right: 50px;

				/** Mobile. */
				@media (max-width: 1023px) {
					margin: 0 0 20px;
				}
			}

			.content {

				/** Mobile. */
				@media (max-width: 1023px) {
					text-align: center;
				}

				&-title {
					color: #00993F;
					font-size: 24px;
					font-weight: 900;
					margin-bottom: 10px;

					/** Mobile. */
					@media (max-width: 1023px) {
						font-size: 18px;
					}
				}

				&-subtitle {
					color: white;
					font-size: 18px;
					font-weight: 500;

					/** Mobile. */
					@media (max-width: 1023px) {
						font-size: 16px;
					}
				}
			}
		}

		.description {
			width: 780px;
			margin: 0 auto;

			/** Appear. */
			@media (min-width: 1024px) {
				opacity: 0;
				transform: translateY(10px);
				transition: opacity time(default) ease(inout), transform time(default) ease(inout);
				
				&.appear {
					opacity: 1;
					transform: translateY(0px);
				}
			}

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}

			p {
				color: white;
				font-size: 14px;
				font-weight: 500;
				line-height: 1.7;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 16px;
				}
			}
		}
	}
}