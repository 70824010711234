#main-modal-cta {
	background-color: color(blue, base);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 45;
	opacity: 0;
	visibility: hidden;
	transition: opacity time(default) ease(inout), visibility time(default) ease(inout);
	padding: 100px 0 60px;
	overflow: scroll;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.close {
		background-color: white;
		position: absolute;
		top: 0;
		right: 0;
		width: 70px;
		height: 70px;
		z-index: 2;

		svg path {
			fill: color(blue, base);
		}
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		z-index: 1;

		&-title {
			color: white;
			font-size: 21px;
			font-weight: 900;
			line-height: 1.2;
			text-transform: uppercase;

			/** Mobile. */
			@media (max-width: 355px) {
				font-size: 18px;
			}
		}

		&-phone {
			color: color(green);
			font-size: 16px;
			font-weight: 900;
			display: block;
			text-align: center;
			text-transform: uppercase;
			margin: 15px 0 35px;

			/** Mobile. */
			@media (max-width: 355px) {
				font-size: 14px;
				margin: 10px 0 20px;
			}

			a {
				color: white;
				transition: color time(fast) ease(inout);

				&:hover {
					color: color(green);
				}
			}
		}

		.form {
			width: 100%;
			padding: 0 35px;

			.field-group {
				position: relative;

				&:not(:last-child) {
					margin-bottom: 55px;

					/** Mobile. */
					@media (max-width: 355px) {
						margin-bottom: 35px;
					}
				}

				&::before,
				&::after {
					content: '';
					position: absolute;
					right: 0;
					bottom: 0;
					left: 0;
					height: 1px;
					transform-origin: 0% 50%;
					transition: transform time(default) ease(inout);
					transform: scaleX(0);
				}

				&::before {
					background-color: red;
					z-index: 3;
				}

				&::after {
					background-color: color(blue, light);
					z-index: 2;
				}
				
				.placeholder {
					position: absolute;
					top: 1px;
					left: 0;
					color: white;
					font-size: 18px;
					font-weight: bold;
					transform-origin: 0% 0%;
					transition: transform time(fast) ease(inout);
					pointer-events: none;
				}

				.field {
					background-color: transparent;
					color: white;
					font-size: 16px;
					font-weight: 500;
					padding: 0 0 15px;
					width: 100%;
					display: block;
					border: none;
					border-bottom: 1px solid color(green);
					outline: none;
				}

				&.focus,
				&.not-empty {
					.placeholder {
						transform: translateY(-20px) scale(.6);
					}

					&::after {
						transform: scaleX(1);
					}
				}

				&.invalid {
					&::before {
						transform: scaleX(1);
					}
				}
			}

			.form-submit {
				display: flex;
				justify-content: center;
				
				button {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;

					&::before {
						content: '';
						background-color: color(green);
						position: absolute;
						top: 50%;
						right: -28px;
						transform: translateY(-50%);
						width: 80px;
						height: 80px;
						border-radius: 50%;
						transition: background-color time(fast) ease(inout);

						/** Mobile. */
						@media (max-width: 355px) {
							width: 55px;
							height: 55px;
							right: -16px;
						}
					}

					span {
						color: white;
						font-size: 18px;
						font-weight: 900;
						text-transform: uppercase;
						margin-right: 60px;

						/** Mobile. */
						@media (max-width: 355px) {
							margin-right: 40px;
						}
					}

					svg {
						position: relative;
						z-index: 2;
						transition: transform time(fast) ease(inout);

						path {
							fill: white;
						}
					}

					&:hover {
						&::before {
							background-color: color(blue, light);
						}

						svg {
							transform: translate(3px, -3px);
						}
					}
				}
			}
		}
	}
}