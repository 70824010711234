#main-menu {
	background-color: color(blue, base);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 45;
	opacity: 0;
	visibility: hidden;
	transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.close {
		background-color: white;
		position: absolute;
		top: 0;
		right: 0;
		width: 70px;
		height: 70px;
		z-index: 2;

		svg path {
			fill: color(blue, base);
		}
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 35px;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;

		.navigation {
			margin-bottom: 50px;

			ul li {
				display: block;
				text-align: center;
				
				&:not(:last-child) {
					margin-bottom: 35px;
				}

				a {
					color: white;
					font-size: 24px;
					font-weight: bold;
					text-transform: uppercase;
					transition: color time(default) ease(inout);
				}
			}
		}
	}
}