#main-header {
	background-color: color(blue, base);
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 50;
	padding-right: 35px;
	transition: box-shadow time(default) ease(inout);

	/** Mobile. */
	@media (max-width: 1023px) {
		pointer-events: none;
		background-color: unset;
		padding-right: 0;
	}

	.logo {
		position: relative;
		width: 300px;
		height: 100px;
		transition: height time(default) ease(inout);

		/** Mobile. */
		@media (max-width: 1023px) {
			transition: width time(default) ease(inout);
			width: 200px;
			height: 70px;
			pointer-events: initial;
		}

		a {
			background-color: white;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			transition: background-color time(default) ease(inout);

			img {
				max-width: 100%;

				/** Mobile. */
				@media (max-width: 1023px) {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
					transition: clip-path time(default) ease(inout), transform time(default) ease(inout);
					width: 160px;
					max-width: unset;
				}
			}

			/** Desktop. */
			@media (min-width: 1024px) {
				&:hover {
					background-color: darken(white, 10%);
				}
			}
		}
	}

	.navigation ul {
		display: flex;
		align-items: center;

		li {
			&:not(:last-child) {
				margin-right: 65px;
			}

			a {
				color: white;
				font-size: 12px;
				font-weight: bold;
				text-transform: uppercase;
				transition: color time(default) ease(inout);

				&:hover {
					color: color(green);
				}
			}
		}
	}

	.cta button {
		background-color: color(green);
		width: 190px;
		height: 50px;
		border: 1px solid color(green);
		transition: background-color time(default) ease(inout);

		span {
			color: color(blue, base);
			font-size: 12px;
			font-weight: 900;
			text-transform: uppercase;
			letter-spacing: .8px;
			transition: color time(default) ease(inout);
		}

		&:hover {
			background-color: color(blue, base);

			span {
				color: white;
			}
		}
	}

	.menu-toggler {
		background-color: color(blue, base);
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 70px;
		height: 70px;
		transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

		/** Mobile. */
		@media (max-width: 1023px) {
			pointer-events: initial;
		}

		span {
			background-color: white;
			position: absolute;
			height: 2px;
			width: 25px;
			left: 50%;
			transform: translateX(-50%);
			transition: background-color time(default) ease(inout);

			&:nth-child(1) {
				top: calc(50% - 10px);
			}

			&:nth-child(2) {
				top: calc(50% - 1px);
			}

			&:nth-child(3) {
				bottom: calc(50% - 10px);
			}
		}
	}

	/** Sticky header. */
	&.sticky {

		/** Desktop. */
		@media (min-width: 1024px) {
			box-shadow: 0 5px 30px rgba(0, 0, 0, .4);

			.logo {
				height: 80px;
			}
		}

		/** Mobile. */
		@media (max-width: 1023px) {
			.logo {
				width: 70px;

				a img {
					clip-path: polygon(0 0, 30px 0, 30px 100%, 0 100%);
					transform: translate(-15px, -50%);
				}
			}
		}
	}

	/** Menu open. */
	&.menu-open .menu-toggler {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
	}
}

#main-cta {
	position: fixed;
	top: 135px;
	right: 10px;
	z-index: 50;

	/** Mobile. */
	@media (max-width: 1023px) {
		top: unset;
		right: 15px;
		bottom: 75px;
		z-index: 45;
	}

	.box {
		color: white;
		position: absolute;
		top: 50%;
		right: 65px;
		transform: translateY(-50%);
		white-space: nowrap;
		padding: 12px 36px 12px 12px;
		pointer-events: none;

		&::before {
			content: '';
			background-color: color(blue, light);
			position: absolute;
			top: 0;
			right: 24px;
			bottom: 0;
			left: 0;
			z-index: 1;
			box-shadow: 0px 20px 40px 0px rgba(5, 24, 40, 0);
			transform-origin: 100% 50%;
			transform: scaleX(0);
			transition: transform time(fast) ease(inout), box-shadow time(fast) ease(inout);
			transition-delay: .4s, 0s;
		}

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(-10px, -50%);
			width: 12px;
			border-top: 12px solid transparent;
			border-bottom: 12px solid transparent;
			border-left: 12px solid color(blue, light);
			opacity: 0;
			transition: transform time(default) ease(inout), opacity time(default) ease(inout);
			z-index: 2;
		}

		&-title,
		&-phone {
			position: relative;
			z-index: 2;
			opacity: 0;
			transform: translateX(5px);
			transition: transform time(default) ease(inout), opacity time(default) ease(inout);
		}

		&-title {
			font-size: 13px;
			font-weight: bold;
			margin-bottom: 5px;
		}

		&-phone {
			font-size: 16px;
			font-weight: bold;
		}
	}

	.icon {
		background-color: white;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 70px;
		height: 70px;
		border-radius: 50%;
		box-shadow: 0px 20px 40px 0px rgba(5, 24, 40, 0.7);
		transition: background-color time(default) ease(inout);

		/** Mobile. */
		@media (max-width: 1023px) {
			box-shadow: 0px 20px 40px 0px rgba(5, 24, 40, 0.4);
		}

		&-link {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 2;
		}

		/** Animation phone. */
		@keyframes animation_phone {
			0% { transform: rotate(0deg); }
			2% { transform: rotate(-10deg); }
			4% { transform: rotate(0); }
			6% { transform: rotate(10deg); }
			8% { transform: rotate(0); }
			10% { transform: rotate(-10deg); }
			12% { transform: rotate(0); }
			14% { transform: rotate(10deg); }
			16%, 100% { transform: rotate(0); }
		}

		&-vector {
			position: relative;
			z-index: 1;

			svg {
				animation-name: animation_phone;
				animation-duration: 3s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;

				path {
					fill: color(blue, light);
					transition: fill time(default) ease(inout);
				}
			}
		}
	}
	
	/** Mobile. */
	@media (min-width: 1024px) {

		/** Animation. */
		&:hover,
		&.active {
			.box {
				&::before {
					transform: scaleX(1);
					box-shadow: 0px 20px 40px 0px rgba(5, 24, 40, 0.7);
					transition-delay: 0s, .1s;
				}

				&::after {
					opacity: 1;
					transform: translate(0, -50%);
					transition-delay: .1s;
				}

				.box-title,
				.box-phone {
					opacity: 1;
					transform: translateX(0);
				}

				.box-title {
					transition-delay: .2s;
				}

				.box-phone {
					transition-delay: .3s;
				}
			}

			.icon {
				background-color: color(blue, light);

				svg path {
					fill: white;
				}
			}
		}
	}	
}