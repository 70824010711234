#main-footer {
	background-color: color(blue, base);
	position: relative;
	z-index: 2;
	padding: 35px 0;

	/** Mobile. */
	@media (max-width: 1023px) {
		padding: 35px 0 90px;
	}

	.social {
		margin-bottom: 70px;

		/** Mobile. */
		@media (max-width: 1023px) {
			margin-bottom: 45px;
		}

		ul {
			display: flex;
			align-items: center;
			justify-content: center;

			li {
				&:not(:last-child) {
					margin-right: 45px;

					/** Mobile. */
					@media (max-width: 1023px) {
						margin-right: 25px;
					}
				}

				a {
					display: inline-block;
					padding: 3px;

					svg path {
						fill: #C9C6C8;
						transition: fill time(default) ease(inout);
					}

					&:hover svg path {
						fill: color(green);
					}
				}
			}
		}
	}

	.copyright {
		text-align: center;
		
		span {
			color: white;
			font-size: 12px;
			font-weight: 500;
		}
	}

	.assinatura {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 35px;

		a {
			display: inline-block;
			transition: opacity time(default) ease(inout);

			&:hover {
				opacity: .7;
			}
		}
	}
}