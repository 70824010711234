/** Animate lazy load. */
.image-lazy-load {
  overflow: hidden;
  
  @keyframes lazy_load {
    0% { transform: scaleX(0); transform-origin: 0% 50%; }
    50% { transform: scaleX(1); transform-origin: 0% 50%; }
    50.1% { transform: scaleX(1); transform-origin: 100% 50%; }
    100% { transform: scaleX(0); transform-origin: 100% 50%; }
  }

  &:not(.no-animate) {
    &::after {
      content: '';
      background-color: color(blue, base);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      transform: scaleX(0);
    }

    img {
      position: relative;
      z-index: 1;
      transform-origin: 25% 25%;
      opacity: 0;
      transform: scale(1.05);
      transition: opacity 2s ease(inout), transform 2s ease(inout);
    }
  }

  &.no-animate img {
    opacity: 0;
    transition: opacity time(slow) ease(inout);
  }

  &.loaded {
    &:not(.no-animate) {
      &::after {
        animation-name: lazy_load;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-timing-function: ease(inout);
      }

      img {
        transition-delay: .7s;
        transform: scale(1);
      }
    }

    img {
      opacity: 1;
    }
  }
}