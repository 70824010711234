/** @import: Vendor. */
@import "../vendor/normalize/normalize.css";
@import "../vendor/fancybox/jquery.fancybox.min.css";

/** @import: Main. */
@import "reset";
@import "vars";
@import "fonts";

/** Body. */
body {
  font-family: 'Montserrat';
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms;
  }

  &.shadow {
    width: 100%;
    overflow: hidden;

    &::before {
      opacity: .6;
      pointer-events: initial;
    }
  }
}

.container {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;

	@media (max-width: 1023px) {
		padding: 0 25px;
	} 
}

/** @import: Elements. */
@import "elements/lazy-load";
@import "elements/progress-screen";

/** @import: Globals. */
@import "globals/header";
@import "globals/footer";
@import "globals/menu";
@import "globals/cta";
@import "globals/modal";

/** @import: Pages. */
@import "pages/home";